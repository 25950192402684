import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { setRequirementStatus } from '../../redux/designerReducers/canvasReducer'
import { ReactComponent as QBrandIcon } from '../../assets/qbrand.svg'
import { ReactComponent as QBudgetIcon } from '../../assets/qbudget.svg'
import { ReactComponent as QColorIcon } from '../../assets/qcolor.svg'
import { ReactComponent as QStyleIcon } from '../../assets/qstyle.svg'
import { ReactComponent as QCategoryIcon } from '../../assets/qcategory.svg'

const QIcon = styled.svg`
  height: 12px;
  margin-right: 2px;

  @media only screen and (min-width: 1024px) {
    height: 24px;
    margin-right: 5px;
  }
`

const Label = styled.div`
  margin-right: 8px;
  text-transform: capitalize;
`

const RequirementContainer = styled.div`
  height: 30px;
  font-size: 10px;
  padding: 0 8px;

  background-color: ${props => (props.bg !== '' ? props.bg : props.theme.pill)};
  color: ${props => (props.bg !== '' ? 'black' : props.theme.text.primary)};
  cursor: ${props => (props.haveAction === 'false' ? 'default' : 'pointer')};

  box-sizing: border-box;
  border-radius: 22px;

  /*margin-right: 8px;
  margin-bottom: 8px;*/
  font-weight: 500;

  white-space: nowrap;

  display: flex;
  align-items: center;
  align-content: center;
  align-self: center;

  border: ${props => (props.error === true ? '2px solid #FF5E6D' : 'none')};

  @media only screen and (min-width: 1024px) {
    height: 44px;
    font-size: 14px;
    padding: 0 15px;
  }
`

const Radio = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid ${({ isDone }) => (isDone ? '#4FC122' : '#ff5e6d')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;

  &:after {
    content: '';
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: ${({ isDone }) => (isDone ? '#4FC122' : '#ff5e6d')};
  }
`

const RadioRequired = styled(Radio)`
  background-color: #ff5e6d;
  position: relative;

  ${({ isDone }) =>
    isDone
      ? `
      background-color: #4FC122;
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%) rotate(45deg);
        height: 8px;
        width: 4px;
        border-bottom: 1.5px solid #fff;
        border-right: 1.5px solid #fff;
        background-color: transparent;
        border-radius: 0;
      }`
      : `
  &:after, &:before {
    content: '';
    display: block;
    background-color: #fff;
    width: 11px;
    height: 1px;
    border-radius: 2px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  `}
`

const cap = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const RequirementPill = ({
  data,
  colorize,
  index,
  onClick,
  designType,
  ...props
}) => {
  const [label, setLabel] = useState()
  const [icon, setIcon] = useState(QBudgetIcon)
  const [bg, setBg] = useState('')
  const [done, setDone] = useState(false)
  const [error, setError] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setRequirementStatus({ index, isCompleted: done }))
  }, [done])

  useEffect(() => {
    if (!data) return
    setError(data.error)
    setDone(false)

    var mod = ''
    var cat = data.item.category || ''

    setBg('')

    switch (data.kind) {
      case 'color':
        mod = data.attr.color
        if (colorize) setBg('#fff2f3')
        setIcon(QColorIcon)
        break
      case 'brand':
        mod = data.attr.store
        if (colorize) setBg('#D3DFF9')
        setIcon(QBrandIcon)
        break
      case 'style':
        mod = data.attr.style
        if (colorize) setBg('#F3E3F4')
        setIcon(QStyleIcon)
        break
      case 'budget':
        mod = data.attr.color
        if (colorize) setBg('#DFF5D4')
        setIcon(QBudgetIcon)
        break
      default:
        setIcon(QCategoryIcon)
        if (colorize) setBg('#FFF3C0')
        cat = cap(cat)
    }

    const subcat = data.item.subcategory || ''

    var str

    if (data.kind === 'budget') str = cap(data.kind) + ': '
    else
      str =
        cap(data.kind) + ': ' + data.qty + ' ' + mod + ' ' + cat + ' ' + subcat

    if (data.count >= 0) {
      str = str + ' (' + data.count + '/' + data.qty + ')'
      if (data.count >= data.qty) {
        setDone(true)
      } else {
        setDone(false)
      }
    }
    setLabel(str)
  }, [data, colorize])

  if (data.kind !== 'budget')
    return (
      <RequirementContainer
        bg={bg}
        error={error}
        onClick={e => {
          if (!onClick) return
          onClick(data)
        }}
        haveAction={onClick ? 'true' : 'false'}
        {...props}
      >
        {onClick &&
          (designType !== 'userEvent' || data.kind === 'color' ? (
            <RadioRequired isDone={done} />
          ) : (
            <Radio isDone={done} />
          ))}
        <QIcon as={icon} />
        <Label>{label}</Label>
      </RequirementContainer>
    )

  return <RequirementContainer />
}

export default RequirementPill
